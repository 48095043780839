<template>
    <div :class="`${section} layout`">
        <div class="shadow">
            <div class="shadow-top"></div>
            <div class="shadow-bottom"></div>
        </div>
        <section :class="classMod('left')" :aria-label="ariaLabel">
            <div class="shadow-top-cover"></div>
            <slot name="left-panel"></slot>
            <div class="shadow-bottom-cover"></div>
        </section>
        <section :class="classMod('right')" :style="{ 'background-color': card.background_color }" ref="rightPanel" aria-label="Greeting Card Image">
            <button class="-transparent-button -close" @click="popOut" v-if="isOpen"><close/></button>
            <div v-if="showSection" :class="`${section}-right__container`" v-cloak ref="envelopeImg">
                <img :class="`${section}-right__img`" src="/assets/images/plain-envelope.jpg" alt="plain envelope">
                <p :class="`${section}-right__name`">
                    <span class="-to">To</span>
                    {{ invitation.recipient_first_name }} {{ invitation.recipient_last_name}}
                </p>
            </div>
            <div :class="classMod('right__container')" v-else ref="rightContainer">
                <img
                    :class="classMod('right__img', ['-back-envelope'])"
                    :src="`${storageUrl}/${card.envelope_back}`"
                    alt=""
                    @load="loaded"
                >
                <div :class="classMod('right__img', ['-card'])" ref="cardImg">
                    <img class="-card__img" :src="`${storageUrl}/${cardImage(card)}`" :alt="card.alt_text" @click="popOut" @load="loaded">
                </div>
                <img
                    :class="classMod('right__img', ['-front-envelope'])"
                    :src="`${storageUrl}/${card.envelope_front}`"
                    alt=""
                    ref="front"
                    @load="loaded"
                >
            </div>
        </section>
        <breadcrumbs :active="breadcrumb" v-if="showBreadcrumbs"/>
    </div>
</template>

<script>
import $ from 'wee-dom';
import $screen from 'wee-screen';
import close from './close.vue';
import { imageMixin } from '../../scripts/mixins.js';
import Breadcrumbs from '../breadcrumbs/breadcrumbs.vue';

export default {
    name: 'Layout',
    components: { close, Breadcrumbs },
    mixins: [imageMixin],
    props: {
        section: String,
        showBreadcrumbs: Boolean,
        invitation: {
            type: Object,
            default: () => {},
        },
        breadcrumb: String,
        card: Object,
        hideFooter: Boolean,
    },
    data: () => ({
        storageUrl: `${location.origin}/storage`,
        load: 0,
        isOpen: false,
    }),
    computed: {
        showSection() {
            return this.section === 'send' && this.invitation.is_sent;
        },
        ariaLabel() {
            switch (this.section) {
                case 'form':
                case 'edit':
                    return 'Greeting Card Form';
                    break;
                case 'preview':
                case 'detail':
                case 'show':
                    return 'Greeting Card Preview';
                    break;
                case 'send':
                    return 'Greeting Card Sent';
                    break;
                default:
                    return 'Greeting Card Preview';
                    break;
            }
        }
    },
    methods: {
        classMod(mod, extra = []) {
            return [`layout-${mod}`, `${this.section}-${mod}`, ...extra];
        },
        popOut(event) {
            if ($screen.size() > 3) return;

            const { rightPanel } = this.$refs;

            if (rightPanel.classList.contains('-pop-out')) {
                this.isOpen = false;
                rightPanel.classList.remove('-pop-out');
            } else {
                this.isOpen = true;
                rightPanel.classList.add('-pop-out');
            }
        },
        loaded() {
            const { cardImg, front, rightContainer } = this.$refs;
            this.load++;

            if (this.load !== 3) {
                return;
            }

            rightContainer.style.opacity = 1;

            if (['detail', 'show'].includes(this.section)) {
                cardImg.classList.add('-animate');

                cardImg.addEventListener('animationend', () => {
                    front.classList.add('-final-form');
                    cardImg.classList.add('-final-form');
                    cardImg.style.animation = 'none';
                });
            }
        }
    },
    mounted() {
        const { envelopeImg } = this.$refs;

        if (envelopeImg) {
            setTimeout(() => {
                envelopeImg.classList.add('-pop');
            }, 100);
        }

        if (this.hideFooter) {
            $('.footer').hide();
        }
    },
}
</script>
