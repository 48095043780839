import $router from 'wee-routes';
import './bootstrap';
import header from '../components/header';
import share from '../components/share';
import slider from '../components/slider';
import home from '../components/home';
import vue from './vue';
import facts from '../components/facts';
import footer from '../components/footer';
import invite from '../components/invite';
import show from '../components/invitation-show';
import acsCard from '../components/acs-card-envelope';

const common = [
    header,
    footer,
    share,
];

$router.map([
    {
        path: '/',
        handler: [
            ...common,
            slider,
            home,
            acsCard,
        ],
    },
    {
        path: '/cards',
        handler: [
            ...common,
            vue,
        ],
    },
    {
        path: '/cards/:id',
        handler: [
            ...common,
            vue,
            invite,
        ],
    },
    {
        path: '/invite/create/:id',
        handler: [
            ...common,
            vue,
        ],
    },
    {
        path: '/invite/:id/preview',
        handler: [
            ...common,
            vue,
            show,
            slider,
        ],
    },
    {
        path: '/invite/:id/edit',
        handler: [
            ...common,
            vue,
        ],
    },
    {
        path: '/invite/:id',
        handler: [
            ...common,
            vue,
            invite,
            show,
            slider,
        ],
    },
    {
        path: '/facts',
        handler: [
            ...common,
            facts,
            invite,
            acsCard,
        ],
    },
    {
        path: '/about',
        handler: [
            ...common,
            acsCard,
        ],
    },
    {
        path: '*',
        handler: [
            ...common,
        ],
    },
]).run();
