import { RouteHandler } from 'wee-routes';
import $ from 'wee-dom';
import $events from 'wee-events';

let pause = false;

const runSlider = (slider, length, direction = 'reverse') => {
    const child = slider.children()[0];
    const childrenWidth = $(child).width() * length;
    let left = 0;
    let speed = 1;

    const slide = () => {
        speed = pause ? speed - 0.01 : 1;

        const amount = speed < 0 ? 0 : speed;

        if (direction === 'reverse') {
            left += amount;
        } else {
            left -= amount;
        }

        slider.css('transform', `translateX(${left}px)`);

        if (Math.abs(left) > childrenWidth) {
            left = 0;
        }
    };

    setInterval(slide, 30);
};


/*
 * Homepage hero slider
 * If user hasn't visited website, slide the first four images into place,
 * then start the slide animation after
 *
 * If they have visited remove the slide class,
 * then wait for page to fade in before slide animation starts
 */
const runHeroSlider = (heroSlider) => {
    const first4Children = heroSlider.children().slice(0, 5);
    const hasVisited = sessionStorage.getItem('has_visited') === 'true';
    let timeout;

    if (hasVisited) {
        first4Children.each((child, i) => {
            $(child).removeClass(`-slide-in-${i + 1}`);
        });
        timeout = 600;
    } else {
        first4Children.each((child) => {
            $(child).addClass('-move');
        });
        timeout = 1600;
    }

    setTimeout(() => {
        runSlider(heroSlider, 8, 'forward');
    }, timeout);
};

export default new RouteHandler({
    init() {
        const heroSlider = $(':homepage-hero-slider');
        const factsSlider = $(':facts-cta-slider');
        const slideItems = $(':slide-item');

        if (heroSlider.is('section')) {
            runHeroSlider(heroSlider);
        }

        if (factsSlider.is('section')) {
            runSlider(factsSlider, 5);
        }

        $events.on(slideItems, {
            mouseenter() {
                pause = true;
            },
            mouseleave() {
                pause = false;
            },
        });
    },
});
