import { render, staticRenderFns } from "./close.vue?vue&type=template&id=58b2f65c&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

component.options.__file = "close.vue"
export default component.exports