import Vue from 'vue/dist/vue.esm';
import $ from 'wee-dom';
import { RouteHandler } from 'wee-routes';
import registerCardsListComponents from '../components/cards-list';
import registerLayoutComponents from '../components/invite-layout';
import registerFormComponents from '../components/invite-form';

export default new RouteHandler({
    init() {
        if ($('#app').length === 1) {
            registerCardsListComponents();
            registerLayoutComponents();
            registerFormComponents();

            // eslint-disable-next-line no-unused-vars
            const vue = new Vue({
                el: '#app',
            });
        }
    },
});
