import { RouteHandler } from 'wee-routes';
import AOS from 'aos/dist/aos';

export default new RouteHandler({
    init() {
        AOS.init({
            offset: 130,
            duration: 1000,
            easing: 'ease-out',
            disable: 'phone',
        });
    },
});
