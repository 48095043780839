import { RouteHandler } from 'wee-routes';
import $events from 'wee-events';
import { $setVar } from 'wee-store';
import { $setRef } from 'core/dom';
import $ from 'wee-dom';
import { _win } from 'core/variables';

export default new RouteHandler({
    init() {
        $setVar();
        $setRef();

        const newImg = $(':newImg');
        const cardId = newImg.attr('data-cardId');

        $events.on(':facebook', 'click', () => {
            FB.ui({
                method: 'share',
                display: 'iframe',
                href: `${_win.location.origin}/cards/${cardId}?facebook=true`,
            }, () => {});
        });
    },
});
