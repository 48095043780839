import { RouteHandler } from 'wee-routes';
import $events from 'wee-events';
import { _win } from 'core/variables';

export default new RouteHandler({
    init() {
        $events.on(':facebook-share', 'click', () => {
            FB.ui({
                method: 'share',
                display: 'iframe',
                href: _win.location.origin,
            }, () => {});
        });
    },
});
