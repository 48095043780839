import { render, staticRenderFns } from "./arrow.vue?vue&type=template&id=38f67de8&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

component.options.__file = "arrow.vue"
export default component.exports