import { RouteHandler } from 'wee-routes';
import $events from 'wee-events';
import $ from 'wee-dom';
import { _win } from 'core/variables';

const getWindowOptions = () => {
    const width = 500;
    const height = 350;
    const left = (_win.innerWidth / 2) - (width / 2);
    const top = (_win.innerHeight / 2) - (height / 2);

    return [
        'resizable,scrollbars,status',
        'height=' + height,
        'width=' + width,
        'left=' + left,
        'top=' + top,
    ].join();
};

export default new RouteHandler({
    init() {
        const twitterShare = $(':twitter-share');
        const shareUrl = `https://twitter.com/intent/tweet?hashtags=ColonCancerAwareness,CordiallyStopCancer&url=${_win.location.origin}`;
        twitterShare.attr('href', shareUrl);

        $events.on(twitterShare, 'click', (e) => {
            const win = _win.open(shareUrl, 'ShareOnTwitter', getWindowOptions());
            win.opener = null;
            e.preventDefault();
        });
    },
});
