<template>
    <article>
        <section aria-label="Choose a Message" class="choose-card">
            <h1 class="choose-card__header">Choose a Message</h1>
            <div class="choose-card__container">
                <div class="choose-card__card -card" v-for="card in cards" :key="card.id">
                    <a :href="`cards/${card.id}`" class="choose-card__link"><img class="choose-card__img -card__img -hover" :src="`../storage/${cardImage(card)}`" :alt="card.alt_text"></a>
                </div>
            </div>
            <breadcrumbs :active="'message'" />
        </section>
    </article>
</template>

<script>
import $ from 'wee-dom';
import $screen from 'wee-screen';
import Breadcrumbs from '../breadcrumbs/breadcrumbs.vue';
import { imageMixin } from '../../scripts/mixins.js';

export default {
    name: 'List',
    components: { Breadcrumbs },
    props: [
        'cards',
    ],
    mixins: [ imageMixin ],
}
</script>
