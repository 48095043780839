import { RouteHandler } from 'wee-routes';
import $events from 'wee-events';
import $ from 'wee-dom';
import { _win } from 'core/variables';
import { slide, hide, fade } from '../../scripts/classes';

function toggleHeader() {
    const navigation = $(':navigation');
    const modalOpen = $(':modal-open');
    const overlay = $(':overlay');
    const body = $(':body');
    let scrollPosition;

    if (_win.scrollY) {
        scrollPosition = _win.scrollY;
    } else {
        scrollPosition = _win.pageYOffset;
    }

    navigation.css('top', `${scrollPosition}px`);

    if (navigation.hasClass(hide)) {
        navigation.attr('aria-hidden', false);
        navigation.removeClass(hide);
        body.css({
            overflow: 'hidden',
        });

        // fix for ios (ignores overflow hidden on body)
        navigation.css({
            'touch-action': 'none',
        });

        overlay.css({
            'touch-action': 'none',
        });

        $events.on(overlay, 'touchmove', (e) => {
            e.preventDefault();
        });

        $events.on(navigation, 'touchmove', (e) => {
            e.preventDefault();
        });

        setTimeout(() => {
            modalOpen[0].blur();
            navigation.addClass(slide);
            overlay.addClass(fade);
        }, 10);

        setTimeout(() => {
            $('.nav-link')[0].focus();
        }, 510);
    } else {
        navigation.removeClass(slide);
        overlay.removeClass(fade);
        body.css({
            overflow: '',
        });

        $events.off(overlay, 'touchmove');
        $events.off(navigation, 'touchmove');

        setTimeout(() => {
            navigation.addClass(hide);
            navigation.attr('aria-hidden', true);
            modalOpen[0].focus();
        }, 500);
    }
}

function escModal(e) {
    if (e.keyCode === 27 && $(':navigation').hasClass(slide)) {
        toggleHeader();
    }
}

function bindEvents() {
    $events.on({
        ':modal-open': {
            click: toggleHeader,
        },
        ':modal-close': {
            click: toggleHeader,
        },
        document: {
            keydown: escModal,
        },
        '.overlay': {
            click(e) {
                if ($(':navigation').hasClass(slide)) {
                    e.preventDefault();
                    toggleHeader();
                }
            },
        },
    });
}

export default new RouteHandler({
    init() {
        bindEvents();
    },
});
