<template>
    <div class="breadcrumbs">
        <div class="breadcrumbs-container">
            <span :class="classList('message')">Message</span>
            <span class="breadcrumbs-arrow"><Arrow /></span>
            <span :class="classList('details')">Details</span>
            <span class="breadcrumbs-arrow"><Arrow /></span>
            <span :class="classList('send')">Send</span>
        </div>
    </div>
</template>
<script>
import Arrow from './arrow.vue';

export default {
    name: 'Breadcrumbs',
    props: ['active'],
    components: { Arrow },
    methods: {
        classList(section) {
            let list = ['breadcrumbs-section'];
            if (section === this.active) {
                list.push('-is-active');
            }

            return list;
        },
    },
}
</script>
