import { RouteHandler } from 'wee-routes';
import $ from 'wee-dom';

/*
 * Homepage hero transition
 * If user has visited, fade/zoom out animation
 * If new user, header and nav bar with fade and drop in
 *
 * Add has_visited to session storage if it doesn't exist
 */
export default new RouteHandler({
    init() {
        const hasVisited = sessionStorage.getItem('has_visited') === 'true';
        const overlay = $(':overlay');
        const css = {
            opacity: 1,
            transform: 'none',
        };

        if (hasVisited) {
            overlay.addClass('-fade-in');
            overlay.removeClass('home-animation');
        } else {
            overlay.removeClass('-transparent');

            $('.header').css(css);
            $(':home-header').css(css);
            $(':home-link').css(css);
        }

        setTimeout(() => {
            overlay.removeClass('-fade-in');
            overlay.removeClass('-transparent');
        }, 1000);

        if (! hasVisited) {
            sessionStorage.setItem('has_visited', true);
        }
    },
});
