/* eslint-disable camelcase, coming from database */
import $screen from 'wee-screen';

const imageMixin = {
    methods: {
        cardImage(card) {
            const screen = $screen.size();
            const { transforms, card_image } = card;

            if (screen < 3) {
                return transforms.mobile;
            }

            if (screen < 4) {
                return transforms.tablet;
            }

            if (screen < 8) {
                return transforms.desktop;
            }

            return card_image;
        },
    },
};

export { imageMixin };
