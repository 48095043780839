<template>
    <form class="info-form" ref="formTop" method="POST" :action="sendInvite" @submit="checkForErrors">
        <div class="info-form__form-container" v-show="recipientInfo">
            <h2 class="info-form__recipient-heading">Who do you want to invite?</h2>
            <div class="info-form__recipient-form">
                <div class="info-form__field-container">
                    <label class="info-form__label" for="recipient_first_name">Recipient's First Name</label>
                    <input class="info-form__input" type="text" name="recipient_first_name" id="recipient_first_name" v-model="invitation.recipient_first_name">
                    <p class="info-form__error" aria-live="assertive" v-if="errors.recipient_first_name">{{ errors.recipient_first_name }}</p>
                </div>
                <div class="info-form__field-container">
                    <label class="info-form__label" for="recipient_last_name">Recipient's Last Name</label>
                    <input class="info-form__input" type="text" name="recipient_last_name" id="recipient_last_name" v-model="invitation.recipient_last_name">
                    <p class="info-form__error" aria-live="assertive" v-if="errors.recipient_last_name">{{ errors.recipient_last_name }}</p>
                </div>
                <div class="info-form__field-container">
                    <label class="info-form__label" for="recipient_email">Recipient's Email Address</label>
                    <input class="info-form__input" type="text" name="recipient_email" id="recipient_email" v-model="invitation.recipient_email">
                    <p class="info-form__error" aria-live="assertive" v-if="errors.recipient_email">{{ errors.recipient_email }}</p>
                </div>
                <div class="info-form__field-container">
                    <label class="info-form__label" for="message">Send a Message</label>
                    <span class="info-form__optional">Optional (300 Character Limit)</span>
                    <textarea class="info-form__message" type="text" name="message" id="message" v-model="invitation.message"></textarea>
                    <p class="info-form__error" aria-live="assertive" v-if="errors.message">{{ errors.message }}</p>
                </div>
                <input type="hidden" name="_token" :value="csrfToken">
                <input type="hidden" name="card_id" :value="card">
                <input type="hidden" name="id" :value="invitation.id">
                <input name="_method" type="hidden" value="PUT" v-if="invitation.id">
                <div class="info-form__btn-container">
                    <button class="info-form__recipient-button -blue-button" @click="showReturnForm">Your information &#10132;</button>
                </div>
            </div>
        </div>
        <div class="info-form__form-container" v-show="returnInfo">
            <h2 class="info-form__return-heading">What's your return information?</h2>
            <div class="info-form__return-form">
                <div class="info-form__field-container">
                    <label class="info-form__label" for="sender_first_name">Your First Name</label>
                    <input class="info-form__input" type="text" name="sender_first_name" id="sender_first_name" ref="firstInput" v-model="invitation.sender_first_name">
                    <p class="info-form__error" aria-live="assertive" v-if="errors.sender_first_name">{{ errors.sender_first_name }}</p>
                </div>
                <div class="info-form__field-container">
                    <label class="info-form__label" for="sender_last_name">Your Last Name</label>
                    <input class="info-form__input" type="text" name="sender_last_name" id="sender_last_name" v-model="invitation.sender_last_name">
                    <p class="info-form__error" aria-live="assertive" v-if="errors.sender_last_name">{{ errors.sender_last_name }}</p>
                </div>
                <div class="info-form__field-container">
                    <label class="info-form__label" for="sender_email">Your Email Address</label>
                    <input class="info-form__input" type="text" name="sender_email" id="sender_email" v-model="invitation.sender_email">
                    <p class="info-form__error" aria-live="assertive" v-if="errors.sender_email">{{ errors.sender_email }}</p>
                </div>
                <div class="google-recaptcha">
                    <vue-recaptcha size="compact" :sitekey="recaptcha" @verify="recaptchaClick" ref="recaptcha"></vue-recaptcha>
                    <p class="info-form__error" aria-live="assertive" v-if="errors.recaptcha">{{ errors.recaptcha }}</p>
                </div>
                <div class="info-form__btn-container">
                    <button class="info-form__return-button -blue-button" type="submit">Preview Message &#10132;</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import $screen from 'wee-screen';
import $ from 'wee-dom';
import { _win } from 'core/variables';

export default {
    name: 'InviteForm',
    props: {
        recaptcha: String,
        card: Number,
        invitation: {
            type: Object,
            default: () => ({
                recipient_first_name: '',
                recipient_last_name: '',
                recipient_email: '',
                message: '',
                sender_first_name: '',
                sender_last_name: '',
                sender_email: ''
            })
        }
    },
    components: {
        VueRecaptcha
    },
    data() {
        return {
            recipientInfo: true,
            returnInfo: false,
            csrfToken: $('meta[data-set="csrfToken"]').attr('data-value'),
            errors: {},
            isVerified: false,
        };
    },
    computed: {
        sendInvite() {
            return (this.invitation.id ? `/invite/${this.invitation.id}` : '/invite');
        },
    },
    watch: {
        'invitation.message': function (prevMessage, currentMessage) {
            if (currentMessage.length >= 300) {
                this.errors.message = 'Message must be less than 300 characters'
            } else {
                this.errors.message = '';
            }
        }
    },
    methods: {
        showReturnForm(e) {
            this.errors = {};

            this.checkRecipientForm();

            if (Object.keys(this.errors).length === 0) {
                this.recipientInfo = ! this.recipientInfo;
                this.returnInfo = ! this.returnInfo;

                this.goto('formTop');

                this.$nextTick(() => {
                    this.$refs.firstInput.focus();
                })
            }

            e.preventDefault();
        },
        checkRecipientForm() {
            const { recipient_first_name, recipient_last_name, recipient_email, message } = this.invitation;
            const validEmail = this.validEmail(recipient_email);

            if (! recipient_first_name) {
                this.errors.recipient_first_name = "First Name Required";
            }

            if (! recipient_last_name) {
                this.errors.recipient_last_name = "Last Name Required";
            }

            if (! validEmail) {
                this.errors.recipient_email = "Email is Required";
            }

            if (message !== null && message.length > 300) {
                this.errors.message = "Message must be less than 300 characters";
            }
        },
        checkForErrors(e) {
            const { sender_first_name, sender_last_name, sender_email} = this.invitation;
            const validEmail = this.validEmail(sender_email);
            this.errors = {};

            if (! sender_first_name) {
                this.errors.sender_first_name = "First Name Required";
            }

            if (! sender_last_name) {
                this.errors.sender_last_name = "Last Name Required";
            }

            if (! validEmail) {
                this.errors.sender_email = "Email is Required";
            }

            if (! this.isVerified) {
                this.errors.recaptcha = "Please verify that you are human";
            }

            if (Object.keys(this.errors).length === 0) {
                return true;
            }

            e.preventDefault();
        },
        recaptchaClick(res) {
            if (res) {
                this.isVerified = true;
            }
        },
        validEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        goto(refName) {
            const element = this.$refs[refName];
            const top = element.offsetTop;
            const nav = $('header');
            const offset = nav[0].offsetHeight;

            _win.scrollTo(0, top - offset);

            if ($screen.size() > 3) {
                element.scrollIntoView();
            }
        }
    },
    mounted() {
        this.$refs.recaptcha.reset();
    }
}
</script>
